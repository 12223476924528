.section.hero {
    .title {
        overflow: hidden; 
        white-space: nowrap;
        margin: 0 auto;
        letter-spacing: .1em;
        animation: typing 5s steps(50, end);
    }

    .subtitle {
        margin-top: .5em;
    }
}

.section.greeting {
    padding-bottom: 24rem;
}

.section.skills {
    margin-top: -14rem;
    padding-bottom: 1rem;
}


.section.skills .box {
    padding: 0;

    .content {
        .columns {
            .column {
                padding: 50px 30px;
                border-bottom: 1px solid #E6ECF8;

                ul {
                    list-style: none;
                    margin-left: 0;
                    margin-top: 0;

                    li {
                        font-size: 18px;
                    }
                }
            }
        }
    }
}

.experience {
    margin-bottom: 4rem;
}

.section.footer {
    .icon {
        cursor: pointer;

        &:hover {
            color: white;
        }
    }
}

@media screen and (min-width: 769px) {
    .section.skills .box {
        padding: 0;
    
        .content {
            .columns {
                .column {
                    border-right: 1px solid #E6ECF8;
                    padding: 70px 45px;
    
                    &:last-child {
                        border-right: none;
                    }
    
                    ul {
                        list-style: none;
                        margin-left: 0;
                        margin-top: 0;
    
                        li {
                            font-size: 18px;
                        }
                    }
                }
            }
        }
    }
}

@keyframes typing {
    from { width: 0 }
    to { width: 100% }
}